import classnames from 'classnames';
import React from 'react';

import { Button } from './Button';
import { ConditionalWrapper } from './ConditionalWrapper';
import { appConfig } from './config';
import { Div, Span } from './HTMLElement';
import { t } from './i18n/i18n';
import { useModals } from './useModals';
import { UsePriceResult } from './usePrice';
import { formatCurrency, formatFloatPercentage } from './util';
import { PropsWithCustomComponent, VariantsController } from './VariantsController';

export interface BestPriceProps {
  enableZeroPercentage?: boolean;
  priceHelper: Omit<UsePriceResult, 'isPriceInRange' | 'discountPercentage' | 'vat' | 'perUnit'>;
}

const BestPrice = ({ enableZeroPercentage, priceHelper }: BestPriceProps) => {
  const { showModal } = useModals();

  const handleInfoButtonClick = React.useCallback(() => {
    showModal({
      children: <BestPriceModal enableZeroPercentage={enableZeroPercentage} priceHelper={priceHelper} />,
      className: 'best-price-modal',
      size: 'default',
      title: t('misc.bestPrice.modal.title'),
    });
  }, [enableZeroPercentage, priceHelper, showModal]);

  return (
    <Div className="best-price">
      {priceHelper.bestPriceValue !== undefined && (
        <>
          <Span marginEnd={1}>{t('misc.bestPrice.priceLabel')}</Span>
          <ConditionalWrapper condition={priceHelper.bestPriceDiscountPercentage !== undefined} wrapper={<del />}>
            <Span
              className={classnames(
                priceHelper.bestPriceDiscountPercentage !== undefined ? 'crossed-out-price' : 'price'
              )}
              marginEnd={1}
            >
              {formatCurrency(priceHelper.bestPriceValue)}
            </Span>
          </ConditionalWrapper>
          {priceHelper.bestPriceDiscountPercentage ? (
            <Span marginEnd={1} uiClassName={{ bs5: 'text-promo' }}>
              {`-${formatFloatPercentage(priceHelper.bestPriceDiscountPercentage)}%`}
            </Span>
          ) : (
            enableZeroPercentage && (
              <Span marginEnd={1} uiClassName={{ bs5: 'text-promo' }}>
                {'-0%'}
              </Span>
            )
          )}
          <Button
            iconStart={appConfig.icons?.info ? { name: appConfig.icons.info, size: 20 } : undefined}
            label={appConfig.icons?.info ? undefined : t('misc.bestPrice.info')}
            onClick={handleInfoButtonClick}
            variant="blank"
          />
        </>
      )}
    </Div>
  );
};

export type BestPriceVariants = '';

const BestPriceController = (props: PropsWithCustomComponent<BestPriceProps>) => (
  <VariantsController<BestPriceProps, BestPriceVariants>
    {...props}
    variantsControllerConfig={{
      defaultComponent: BestPrice,
      name: 'BestPrice',
    }}
  />
);

export { BestPriceController as BestPrice };

export interface BestPriceModalProps extends BestPriceProps {}

const BestPriceModal = ({ enableZeroPercentage, priceHelper }: BestPriceModalProps) => {
  return (
    <>
      <ol>
        <li>
          <div className="price-container d-flex align-items-center">
            {priceHelper.crossedOutPrice && (
              <Span className="crossed-out-price" marginEnd={2}>
                <del>
                  {t('misc.price', {
                    value: formatCurrency(priceHelper.crossedOutPrice),
                  })}
                </del>
              </Span>
            )}
            <Span className="price" uiClassName={{ bs5: priceHelper.crossedOutPrice ? 'text-promo' : undefined }}>
              {t('misc.price', {
                value: formatCurrency(priceHelper.price),
              })}
            </Span>
          </div>
        </li>
        {priceHelper.bestPriceValue && (
          <li>
            <Span marginEnd={1}>{t('misc.bestPrice.priceLabel')}</Span>
            <ConditionalWrapper condition={priceHelper.bestPriceDiscountPercentage !== undefined} wrapper={<del />}>
              <Span marginEnd={1}>{formatCurrency(priceHelper.bestPriceValue)}</Span>
            </ConditionalWrapper>
            {priceHelper.bestPriceDiscountPercentage ? (
              <Span marginEnd={1} uiClassName={{ bs5: 'text-promo' }}>
                {`-${formatFloatPercentage(priceHelper.bestPriceDiscountPercentage)}%`}
              </Span>
            ) : (
              enableZeroPercentage && (
                <Span marginEnd={1} uiClassName={{ bs5: 'text-promo' }}>
                  {'-0%'}
                </Span>
              )
            )}
          </li>
        )}
      </ol>
      <ol>
        <li>{t('misc.bestPrice.modal.priceToday')}</li>
        {priceHelper.bestPriceValue && <li>{t('misc.bestPrice.modal.lowestPrice')}</li>}
      </ol>
    </>
  );
};

export type BestPriceModalVariants = '';

const BestPriceModalController = (props: PropsWithCustomComponent<BestPriceModalProps>) => (
  <VariantsController<BestPriceModalProps, BestPriceModalVariants>
    {...props}
    variantsControllerConfig={{
      defaultComponent: BestPriceModal,
      name: 'BestPriceModal',
    }}
  />
);

export { BestPriceModalController as BestPriceModal };
