import './ProductPage.scss';

import { b2x } from '@b2x/react/src';
import classnames from 'classnames';
import React from 'react';
import SwiperInterface, { FreeMode, Navigation, Thumbs } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { Accordion } from '../Accordion';
import { useAppContext } from '../AppContext';
import { Breadcrumb } from '../Breadcrumb';
import { Button, ButtonVariant } from '../Button';
import { Container } from '../Container';
import { MiscellaneousContentType, ProductContentType } from '../contentTypes';
import { t } from '../i18n/i18n';
import { Icon, IconName, IconSize } from '../Icon';
import { PriceBlock } from '../PriceBlock';
import { Slider } from '../slider/Slider';
import { SliderProducts } from '../slider/SliderProducts';
import { Page } from './Page';

export interface ProductPagePros {}

export const ProductPage = (props: ProductPagePros) => {
  const product = b2x.useProduct<ProductContentType>({ populate: b2x.appConfig.api?.productPopulate });

  const { isFooterCopyrightVisible, isHeaderFirstRowVisible } = useAppContext();

  const miscellaneousContent = b2x.useContent<MiscellaneousContentType>('MISCELLANEOUS_CONTENT');

  const absorbency = product?.attributes?.find((attribute) => attribute.typeCode === 'HAR_Gocce');
  const size = product?.attributes?.find((attribute) => attribute.typeCode === 'HAR_Formato')?.value;

  const [skusDivContainer, scrollToSkusDivContainer] = b2x.useScrollTo(-180);

  const harBoxs = React.useMemo(() => {
    const box1Title = product?.attributes?.find((attribute) => attribute.typeCode === 'HAR_Box_Titolo1')?.value;
    const box1Description = product?.attributes?.find((attribute) => attribute.typeCode === 'HAR_Box1')?.value;
    const box2Title = product?.attributes?.find((attribute) => attribute.typeCode === 'HAR_Box_Titolo2')?.value;
    const box2Description = product?.attributes?.find((attribute) => attribute.typeCode === 'HAR_Box2')?.value;
    const box3Title = product?.attributes?.find((attribute) => attribute.typeCode === 'HAR_Box_Titolo3')?.value;
    const box3Description = product?.attributes?.find((attribute) => attribute.typeCode === 'HAR_Box3')?.value;

    const _harBoxs = [];

    if (box1Description || box1Title) {
      _harBoxs.push({
        description: box1Description,
        title: box1Title,
      });
    }

    if (box2Description || box2Title) {
      _harBoxs.push({
        description: box2Description,
        title: box2Title,
      });
    }

    if (box3Description || box3Title) {
      _harBoxs.push({
        description: box3Description,
        title: box3Title,
      });
    }

    return _harBoxs;
  }, [product]);

  return (
    <Page className="product-page" noPaddingBottom noPaddingTop>
      {product !== undefined && (
        <b2x.ProductContext product={product}>
          {(productContext) => (
            <>
              <section className="mb-3 mb-lg-5 pb-3 pb-lg-5">
                <b2x.AddToCartFormHelper product={product} scope="product">
                  {({ fieldsHelper, formik, priceHelper, selectedProductVariant, selectedSku }) => (
                    <b2x.Row gap={0}>
                      <b2x.Col size={{ lg: 6, xs: 12 }}>
                        <div className="bg-gray-100 position-relative">
                          {b2x.appConfig.enableWishlist && (
                            <div className="btn-wishlist-container position-absolute top-0 end-0 p-3">
                              <b2x.WishlistButtonHelper product={product} sku={selectedSku}>
                                {({ handleWishlistButtonClick, inWishlist }) => (
                                  <Button
                                    className="text-primary"
                                    iconEnd={{ name: inWishlist ? 'wishlist-full' : 'wishlist', size: 25 }}
                                    onClick={handleWishlistButtonClick}
                                    variant="blank"
                                  />
                                )}
                              </b2x.WishlistButtonHelper>
                            </div>
                          )}
                          <ProductImageSlider
                            product={product}
                            selectedProductVariant={selectedProductVariant}
                            selectedSku={selectedSku}
                          />
                        </div>
                      </b2x.Col>
                      <b2x.Col className="p-3 p-lg-5" size={{ lg: 6, xs: 12, xxl: 4 }}>
                        <div className="d-none d-lg-block mb-2">
                          <Breadcrumb hideLastItem />
                        </div>
                        {selectedProductVariant.name && (
                          <h1 className="h2 text-blue lh-1">{b2x.formatHtml(selectedProductVariant.name)}</h1>
                        )}
                        {selectedSku?.name && <p className="small text-gray-400">{selectedSku.name}</p>}
                        {absorbency?.value && (
                          <div className="absorbency mb-2 d-flex flex-wrap">
                            <div className="drop-container lh-1 me-1">
                              {[...Array(10)].map((e, i) => (
                                <div
                                  className={classnames('drop', {
                                    'drop-active': absorbency.value && parseInt(absorbency.value) > i,
                                  })}
                                  // eslint-disable-next-line react/no-array-index-key
                                  key={`absorbency-${i}`}
                                ></div>
                              ))}
                            </div>
                            <div className="ps-a extra-small text-gray-400">
                              {absorbency.value} {absorbency.title}
                            </div>
                          </div>
                        )}
                        {selectedProductVariant.descriptionShort && (
                          <div className="description">{b2x.formatHtml(selectedProductVariant.descriptionShort)}</div>
                        )}
                        {selectedProductVariant.code && (
                          <p className="text-gray-400 my-2 small">
                            {t('product.code')}: {selectedProductVariant.code}
                          </p>
                        )}
                        <div className="product-price-container my-3">
                          <div className="d-flex align-items-center">
                            <div>
                              <PriceBlock
                                classNames={{ price: 'fs-5', specialPrice: 'fs-5' }}
                                gap={3}
                                priceHelper={priceHelper}
                              />
                            </div>
                            <div>
                              <span className="small ms-2">{t('misc.vat', { value: priceHelper.vat })}</span>
                            </div>
                          </div>
                          {b2x.appConfig.enableBestPrice && priceHelper.bestPriceValue && (
                            <div className="small mt-1">
                              <b2x.BestPrice priceHelper={priceHelper} />
                            </div>
                          )}
                        </div>
                        {size && (
                          <div className="mb-3">
                            {t('product.size')}: {b2x.formatHtml(size)}
                          </div>
                        )}
                        <b2x.Row gap={3}>
                          <b2x.Col size={4}>
                            <b2x.FormGroup {...fieldsHelper.quantity.formGroup} noMarginBottom>
                              <b2x.Select {...fieldsHelper.quantity.select} includeEmptyOption={false} />
                            </b2x.FormGroup>
                          </b2x.Col>
                          {fieldsHelper.skus.formFields.length > 1 && (
                            <b2x.Col size={8}>
                              <div ref={skusDivContainer}>
                                <b2x.FormGroup {...fieldsHelper.skus.formGroup} noMarginBottom>
                                  <b2x.Select {...fieldsHelper.skus.select}>
                                    {fieldsHelper.skus.formFields.map(({ option, sku }) => (
                                      <b2x.Option
                                        key={sku.id}
                                        {...option}
                                        label={
                                          sku.attributes?.find((attribute) => attribute.typeCode === 'HAR_TAGLIA')
                                            ?.value
                                        }
                                      />
                                    ))}
                                  </b2x.Select>
                                </b2x.FormGroup>
                              </div>
                            </b2x.Col>
                          )}
                          <b2x.Col size={12}>
                            <div className="FormGroup mb-3">
                              <div className="d-grid">
                                {/* <b2x.Button {...fieldsHelper.buttons.submit} /> */}
                                <b2x.AddToCartFormButton<ButtonVariant, IconName, IconSize>
                                  fieldsHelper={fieldsHelper}
                                  selectedSku={selectedSku}
                                />
                              </div>
                            </div>
                          </b2x.Col>
                        </b2x.Row>
                        {(miscellaneousContent?.body.productPage?.taxBenefits?.title ||
                          miscellaneousContent?.body.productPage?.taxBenefits?.description) && (
                          <div className="position-relative ps-4">
                            {miscellaneousContent.body.productPage.taxBenefits.title && (
                              <>
                                <div className="position-absolute top-0 start-0 text-blue">
                                  <Icon name="discount" size={20} />
                                </div>
                                <h6 className="text-blue fw-bold pt-1">
                                  {b2x.formatHtml(miscellaneousContent.body.productPage.taxBenefits.title)}
                                </h6>
                              </>
                            )}
                            {miscellaneousContent.body.productPage.taxBenefits.description && (
                              <p className="mb-0">
                                {b2x.formatHtml(miscellaneousContent.body.productPage.taxBenefits.description)}
                              </p>
                            )}
                          </div>
                        )}
                      </b2x.Col>
                      <b2x.Portal>
                        <b2x.ProductStickyFooter
                          fieldsHelper={fieldsHelper}
                          formik={formik}
                          isVisible={!isHeaderFirstRowVisible && !isFooterCopyrightVisible}
                          priceHelper={priceHelper}
                          product={product}
                          scrollToElement={scrollToSkusDivContainer}
                          selectedProductImage={selectedProductVariant.image}
                          selectedSku={selectedSku}
                        />
                      </b2x.Portal>
                    </b2x.Row>
                  )}
                </b2x.AddToCartFormHelper>
              </section>
              <section className="mb-3 mb-lg-5 pb-3 pb-lg-5">
                <Container>
                  <b2x.Row>
                    <b2x.Col size={12}>
                      <div className="description-text h-100 bg-gray-100 p-3 p-lg-5 px-lg-4">
                        <Accordion
                          id="description"
                          itemBodyClassName="p-0 pt-3"
                          itemButtonClassName="bg-transparent text-black fw-bold p-0"
                          itemClassName="bg-transparent border-0"
                        >
                          <b2x.AccordionItem id="description-item" show title={t('product.description')}>
                            {b2x.formatHtml(product.description)}
                          </b2x.AccordionItem>
                        </Accordion>
                      </div>
                    </b2x.Col>
                    {/*                 
                <b2x.Col size={{ lg: 6, xs: 12 }}>
                  <div className="description-image h-100">
                    <div className="w-100 h-100 bg-gray-100"></div>
                  </div>
                </b2x.Col>
              */}
                  </b2x.Row>
                </Container>
              </section>
              {harBoxs.length > 0 && (
                <DropSlider harBoxs={harBoxs} title={miscellaneousContent?.body.productPage?.dropSlider?.title} />
              )}
              <b2x.RelatedProducts productId={productContext.selectedProductVariant.id}>
                {({ fetching, relatedProducts }) =>
                  fetching ? (
                    <b2x.Loading />
                  ) : (
                    relatedProducts &&
                    relatedProducts.length > 0 && (
                      <b2x.Listing name="Product page - Related products" products={relatedProducts}>
                        <SliderProducts
                          products={relatedProducts}
                          title={miscellaneousContent?.body.productPage?.relatedProducts?.title}
                        />
                      </b2x.Listing>
                    )
                  )
                }
              </b2x.RelatedProducts>
            </>
          )}
        </b2x.ProductContext>
      )}
    </Page>
  );
};

interface ProductImageSliderProps {
  product: b2x.ProductApiDto;
  selectedProductVariant: b2x.ProductApiDto;
  selectedSku?: b2x.SkuApiDto;
}

const ProductImageSlider = ({ product, selectedProductVariant, selectedSku }: ProductImageSliderProps) => {
  const [thumbsSwiper, setThumbsSwiper] = React.useState<SwiperInterface>();

  return (
    <div className="slider-product-page">
      <div className="gallery-slide-container text-center">
        <b2x.Lightbox>
          <Swiper
            modules={[FreeMode, Thumbs, Navigation]}
            navigation
            spaceBetween={0}
            thumbs={{ swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null }}
          >
            {[
              selectedSku?.image ? selectedSku.image : selectedProductVariant.image,
              ...(selectedProductVariant.alternativeImages ? selectedProductVariant.alternativeImages : []),
            ].map(
              (image, index) =>
                image?.src && (
                  // eslint-disable-next-line react/no-array-index-key
                  <SwiperSlide key={`product-slide-${index}`}>
                    <div className="product-slide-image-container">
                      <b2x.LightboxItem bcomImageId={image.id} src={image.src}>
                        <b2x.Image
                          alt={product.name}
                          aspectRatio={b2x.appConfig.productImageAspectRatio}
                          fluid
                          src={image.src}
                        />
                      </b2x.LightboxItem>
                    </div>
                  </SwiperSlide>
                )
            )}
          </Swiper>
        </b2x.Lightbox>
      </div>
      <div className="gallery-thumbnail-container d-none d-lg-flex bg-white pt-4">
        <Swiper
          centerInsufficientSlides
          className="flex-grow-1 auto-width"
          freeMode
          modules={[Thumbs, Navigation, FreeMode]}
          onSwiper={setThumbsSwiper}
          slidesPerView={'auto'}
          spaceBetween={15}
          watchSlidesProgress
        >
          {[
            selectedSku?.image ? selectedSku.image : selectedProductVariant.image,
            ...(selectedProductVariant.alternativeImages ? selectedProductVariant.alternativeImages : []),
          ].map(
            (image, index) =>
              image && (
                // eslint-disable-next-line react/no-array-index-key
                <SwiperSlide key={`product-thumbnail-${index}`}>
                  <b2x.Image
                    alt={`${product.name} thumbnail`}
                    aspectRatio={b2x.appConfig.productImageAspectRatio}
                    className="border border-2 border-gray-200 rounded d-block"
                    fluid
                    height={100}
                    src={image.src}
                    width={100}
                  />
                </SwiperSlide>
              )
          )}
        </Swiper>
      </div>
    </div>
  );
};

interface DropSliderProps {
  harBoxs?: Array<{
    description?: string;
    title?: string;
  }>;
  title?: string;
}

const DropSlider = ({ harBoxs, title }: DropSliderProps) => {
  return (
    <section className="mb-3 mb-lg-5 pb-3 pb-lg-5">
      <Container>
        <h3 className="h2 text-blue text-center mb-3 mb-lg-5">{b2x.formatHtml(title)}</h3>
        <b2x.Row className="justify-content-center">
          <b2x.Col size={{ lg: 10, xl: 11, xs: 12 }}>
            <div className="drop-slider">
              <Slider
                pagination
                responsive={{
                  lg: {
                    slidesPerView: 2,
                  },
                  md: {
                    slidesPerView: 2,
                  },
                  xl: {
                    slidesPerView: 3,
                  },
                }}
                slidesPerView={1}
                spaceBetween={50}
              >
                {harBoxs?.map((harBox, index) => (
                  <SwiperSlide key={harBox.title}>
                    <div className={classnames('drop-item', { 'drop-reverse': index % 2 !== 0 })}>
                      <div>
                        <h5>{b2x.formatHtml(harBox.title)}</h5>
                        <p className="h6">{b2x.formatHtml(harBox.description)}</p>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Slider>
            </div>
          </b2x.Col>
        </b2x.Row>
      </Container>
    </section>
  );
};
